import React from 'react'
import banner from "../../assets/imgs/banner.png"
function Banner() {
    return (
        <div className='md:container px-[3%] md:px-[0] mx-auto border-b border-[#D9D9D9] py-[20px] md:py-[60px] flex-col md:flex-row flex md:items-start md:justify-between '>
            <div className=''>
                <h1 className='text-[#0B0D31] text-[30px] md:text-[60px] md:leading-[71.6px] font-bold'>
                    Biz sizning IT’ingizni <br /> boshqaramiz,
                    siz bo’lsa o’z binesingizni <br /> boshqaring.</h1>
                <p className=' text-[16px] md:text-[36px] font-medium md:leading-[42.96px] my-[30px] md:w-[630px]'>Innovatsion IT yechimlari bilan <br /> biznesingizning uzluksizligini ta'minlang</p>
                <div className='flex   gap-[20px] mb-[20px] md:mb-[0]'>
                    <button className='bg-[#000ED0] py-[12px] px-[16px] md:py-[15px] md:px-[20px] text-[14px] md:text-[18px] font-medium text-[#fff] rounded-lg md:mr-[20px]' >Bepul konsultatsiya belgilang</button>
                    <button className='bg-[#E5E7FA]  text-[#000ED0] py-[12px] px-[16px] md:py-[15px] md:px-[20px] text-[14px] md:text-[18px] font-medium rounded-lg '> Servislarimiz</button>
                </div>
            </div>
            <img className='' width={'630px'} src={banner} alt="" />
        </div>
    )
}
export default Banner