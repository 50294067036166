import axios from 'axios';
import React, {useEffect, useMemo, useState} from 'react'
import {baseUrl} from '../../constants/Constant';

function Expirance() {
    const [experience, setExperience] = useState([])

    useMemo(() => {
        try {
            axios
                .get(
                    `${baseUrl}/statitscs`,
                )
                .then((res) => {
                    setExperience(res.data)

                })
                .catch((err) => {
                });
        } catch (error) {
        }
    }, [])

    return (
        <div className='md:container px-[3%] md:px-[0] mx-auto flex flex-col md:flex-row md:items-center md:justify-between my-[55px]'>
            <h1 className='text-[64px] font-bold'>IT Unisoft</h1>
            {
                experience.length > 0 && experience.map((item, index) => (
                    <div className='grid grid-cols-2 gap-[20px]  md:grid-cols-4 md:gap-[60px]' key={index}>
                        <div className='flex items-center' key={item.id}>
                            <div className='border border-[#D9D9D9] h-[30px] mr-[20px]'>
                            </div>
                            <h1 className='text-[32px] text-[#242627] font-bold'>{item.year} Yil <p
                                className='text-[16px] font-normal mt-[-8px]'>Bozordagi tajribamiz</p></h1>
                        </div>
                        <div className='flex items-center' key={item.id}>
                            <div className='border border-[#D9D9D9] h-[30px] mr-[20px]'>
                            </div>
                            <h1 className='text-[32px] text-[#242627] font-bold'>{item.customer_status}% <p
                                className='text-[16px] font-normal mt-[-8px]'>Mijozlar mamnun</p></h1>
                        </div>
                        <div className='flex items-center' key={item.id}>
                            <div className='border border-[#D9D9D9] h-[30px] mr-[20px]'>
                            </div>
                            <h1 className='text-[32px] text-[#242627] font-bold'>{item.projects_count}+ <p
                                className='text-[16px] font-normal mt-[-8px]'>Tugatilgan loyihalar</p></h1>
                        </div>
                        <div className='flex items-center' key={item.id}>
                            <div className='border border-[#D9D9D9] h-[30px] mr-[20px]'>
                            </div>
                            <h1 className='text-[32px] text-[#242627] font-bold'>{item.employees_count}+<p
                                className='text-[16px] font-normal mt-[-8px]'>Tajribali mutaxassislar</p></h1>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default Expirance
