import React, { useState } from 'react';
import google from "../../assets/imgs/image8-2.webp";
import apple from '../../assets/imgs/apple-logo-1998.png';
import eduon from '../../assets/imgs/channels4_profile.jpg';
import amazon from '../../assets/imgs/content_1.jpg';
import unired from '../../assets/imgs/unnamed.webp';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay'
import { Autoplay, Navigation, Pagination, Scrollbar, A11y, } from 'swiper/modules';


function GoodOne() {
    return (
        <div className='md:container px-[3%] md:px-0 mx-auto py-[80px] md:py-[120px] grid grid-cols-1 md:grid-cols-2 gap-[84px] relative'>
            <div>
                <button className='bg-[#F5F5F5] rounded-lg py-[8px] px-[10px] text-[#5F6567] text-[13px] font-bold cursor-default'>KIMLAR BILAN ISHLAYMIZ ?</button>
                <h1 className='text-[32px] md:text-[48px] font-bold py-[20px]'>Eng yaxshilar tomonidan tan olingan</h1>
                <p className='text-[16px] md:text-[28px] font-normal text-[#666666]'>Biz ko’plar tashkilot va korxonlar bilan muntazam aloqamiz va ko’plab loyihalarni birgalikda amalga oshirib kelmoqdamiz!</p>
            </div>
            <div className='linier w-[98%] md:w-[600px] h-[347px] py-[37px] absolute left-2 top-[54%] md:top-[21%] rounded-lg md:left-[53.2%] z-30'>
            </div>
            <div className='mt-[40px]'>
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    spaceBetween={30}
                    slidesPerView={3}
                    navigation
                    speed={1000}
                    autoplay={{
                        delay: 700,
                        disableOnInteraction: false,
                        pauseOnMouseEnter:true
                    }}
                    loop={true}
                >
                    <SwiperSlide><img className='w-[100%] h-[121px] object-contain  ' src={google} alt="" /></SwiperSlide>
                    <SwiperSlide><img className='w-[100%] h-[121px] object-contain' src={apple} alt="" /></SwiperSlide>
                    <SwiperSlide><img className='w-[100%] h-[121px] object-contain ' src={eduon} alt="" /></SwiperSlide>
                    <SwiperSlide><img className='w-[100%] h-[121px] object-contain' src={amazon} alt="" /></SwiperSlide>
                    <SwiperSlide><img className='w-[100%] h-[121px] object-contain ' src={unired} alt="" /></SwiperSlide>
                </Swiper>
                <Swiper
                    className='mt-[30px] '
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    spaceBetween={30}
                    slidesPerView={3}
                    navigation
                    speed={1000}
                    loop={true}
                    autoplay={{
                        delay: 700,
                        disableOnInteraction: false,
                        reverseDirection: true,
                        pauseOnMouseEnter: true
                    }}

                >
                    <SwiperSlide><img className='w-[100%] h-[121px] object-contain ' src={unired} alt="" /></SwiperSlide>
                    <SwiperSlide><img className='w-[100%] h-[121px] object-contain ' src={eduon} alt="" /></SwiperSlide>
                    <SwiperSlide><img className='w-[100%] h-[121px] object-contain' src={amazon} alt="" /></SwiperSlide>
                    <SwiperSlide><img className='w-[100%] h-[121px] object-contain' src={apple} alt="" /></SwiperSlide>
                    <SwiperSlide><img className='w-[100%] h-[121px] object-contain ' src={google} alt="" /></SwiperSlide>
                </Swiper>
            </div>
        </div>
    );
}

export default GoodOne;
