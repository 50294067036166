import React, {useEffect, useMemo, useState} from 'react'
import contactImg from "../../assets/imgs/contact.png"
import contactImgSm from "../../assets/imgs/contactSm.png"
import axios from 'axios';
import {baseUrl} from '../../constants/Constant';

function Contact() {
    const [contact, setContact] = useState([])

    useMemo(() => {
        try {
            axios
                .get(
                    `${baseUrl}/contacts`,
                )
                .then((res) => {
                    setContact(res.data)
                })
                .catch((err) => {
                });

        } catch (error) {
        }
    }, [])


    return (
        <div className='flex items-center justify-center flex-col py-[60px]'>
            {contact.map(item => (
                <>
                    <button
                        className='bg-[#F5F5F5] py-[8px] px-[10px] text-[#5F6567] font-bold text-[13px] rounded-lg cursor-default'>Aloqa
                    </button>
                    <h1 className='text-[32px] md:text-[48px] text-center md:text-left font-bold text-[#0B0D31] my-[20px]'>Biz yordam berishga tayyormiz</h1>
                    <div className='flex items-center px-[3%] mb-[20px] '>
                        <p className='border-r border-[#D9D9D9] pr-[14px] md:pr-[60px] text-[16px] md:text-[24px] font-medium'>Bizga qo’ng’iroq
                            qiling:<br/>
                            {item.phone}
                        </p>
                        <p className='ml-[14px] md:ml-[60px] text-[16px] md:text-[24px] font-medium'>E-mail orqali yozing: <br/>
                            {item.email}</p>
                    </div>
                    <button className='bg-[#000ED0] py-[15px] px-[20px] text-[#fff]  text-[18px] rounded-lg'>
                        Bepul konsultatsiya oling
                    </button>
                    <img className='md:container mx-auto my-[60px] hidden md:block  rounded-lg' src={contactImg} alt=""/>
                    <img className='mt-[24px] w-full mb-[48px]  md:hidden' src={contactImgSm} alt=""/>
                    <div className='md:container px-[3%] md:px-0 mx-auto flex flex-col md:flex-row gap-[30px]'>
                        <div>
                            <h1 className='text-[32px] md:text-[48px] font-bold'>Bizning manzilimiz</h1>
                            <p className='text-[14px] md:text-[24px] text-[#666666] my-[20px] md:w-[665px]'>{item.address}</p>
                            <span className='text-[#000ED0] text-[14px] md:text-[24px]'>Afrosiyob 4</span>
                        </div>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1498.6503389191348!2d69.26724413878486!3d41.30232210780483!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8adedfb03223%3A0x6dd78cd99c11e985!2z0YPQu9C40YbQsCDQkNGE0YDQvtGB0LjQsNCxIDQsINCi0LDRiNC60LXQvdGCLCBUb3Noa2VudCBTaGFocmksINCj0LfQsdC10LrQuNGB0YLQsNC9!5e0!3m2!1sru!2s!4v1707309878416!5m2!1sru!2s"
                            width="665" height="300"
                            style={{border: 0, borderRadius: "10px"}}
                            allowFullScreen=""
                            loading="lazy"
                            className='md:block hidden'
                            referrerPolicy="no-referrer-when-downgrade">
                        </iframe>
                     
                    </div>
                </>
            ))}
        </div>
    )
}

export default Contact
