import React from 'react'
import radioBlue from '../../assets/icons/radioBlue.svg'
import radio from '../../assets/icons/radio.svg'
import storyImg from '../../assets/imgs/storyImg.png'
import storyImg2 from '../../assets/imgs/storyImg2.png'
import storyImg3 from '../../assets/imgs/storyImg3.png'
import './StoryDec.css'

function StoryDec() {
  return (
    <div className=''>
      <div className='container mx-auto items-center justify-center'>
        <div className='w-full grid align-center decContainer'>
          <img className='w-full' src={storyImg} alt="" />

          <div className='relative flex items-center justify-center'>
            <div className='border-[#D9D9D9] border w-[1px] h-[510px]'>
            </div>
            <img className='absolute top-0 left-[50%] transCenter' src={radioBlue} alt="" />
            <img className='absolute left-[50%] transCenter' src={radio} alt="" />

          </div>
          <div>
            <h3 className='text-[24px] font-semibold'>
              Boshlanishi
            </h3>
            <p className='text-[16px] font-normal'>
              Bizning texnologiyalar olamiga sayohatimiz bundan 5 yil ilgari o’zimizning bozorga dasturiy ta’minot, web-saytlar va kichik mobil ilovalar ishlab chiqarish bilan boshlangan.
            </p>
          </div>
        </div>
        <div className='w-full grid align-center decContainer'>
          <div>
            <h3 className='text-[24px] font-semibold'>
              Missiyamiz
            </h3>
            <p className='text-[16px] font-normal'>
              IT Unisoftda biz mahalliy va horijiy mijozlarga xizmat ko’rsatuvchi, global miqyosda raqobatdosh, eng kuchli va mizjozlar uchun qulay bo’lgan dasturiy ta’minot va mahsulotlar ishlab chiqarishdir. Buning uchun biz professional mahalliy jamoadan iborat jamoamiz bilan tinimsiz ishlamoqdamiz.
            </p>
          </div>

          <div className='relative flex items-center justify-center'>
            <div className='border-[#D9D9D9] border w-[1px] h-[510px]'>
            </div>

            <img className='absolute left-[50%] transCenter' src={radio} alt="" />

          </div>
          <img className='w-full' src={storyImg2} alt="" />

        </div>
        <div className='w-full grid align-center decContainer'>

          <img className='w-full' src={storyImg3} alt="" />
          <div className='relative flex items-center justify-center'>
            <div className='border-[#D9D9D9] border w-[1px] h-[510px]'>
            </div>
            <img className='absolute left-[50%] transCenter' src={radio} alt="" />

          </div>
          <div>
            <h3 className='text-[24px] font-semibold'>
              Biz Globalmiz
            </h3>
            <p className='text-[16px] font-normal'>
              Hozirda biz nafaqat mahalliy mijozlar va hamkorlar bilan balki chet-eldagi mijoz va hamkorlar bilan ishlab bormoqdamiz. Bizning geografiyaga “Hamdo’stlik mamlakatlari”, Yevropa va Osiyoning 10dan oshiq mamlakatlari kiradi va biz bu ko’rsatkichlarni yil sayin yaxshilab bormoqdamiz.
            </p>
          </div>

        </div>

      </div>
      <div className='mx-auto my-[75px] ml-[20%]'>
        <button className='bg-[#F5F5F5] py-[8px] px-[10px] text-[13px] rounded-lg text-[#5F6567] font-bold cursor-default'>NEGA AYNAN BIZ?</button>
        <p className='text-[#666666] text-[28px] font-normal w-[80%] mt-[20px]'>Biz biznes tartibsiz bo'lishi mumkinligini tushunamiz va shu yerda bizning jamoamiz ishga kirishadi. Sizning biznesingizda oltin o’rtalikni topamiz va shu asosda muammoingizni yechamiz. Biz buni mijozlarimiz bilan haqiqiy sheriklik aloqalarini o'rnatish orqali amalga oshiramiz. Biz bilan ishlaganingizda, og'riqli nuqtalaringizni va maqsadlaringizni tushunadigan jamoa bilan ishlaysiz. Biz sizga betartiblik o'rtasida tartib topishga yordam beramiz.</p>
      </div>
    </div>
  )
}

export default StoryDec