import React from 'react'
import unired from "../../assets/imgs/unired.png"
import expo from "../../assets/icons/export.svg"
import { Link } from 'react-router-dom'
import { baseUrl } from '../../constants/Constant'

function TabItem({ product, clickId }) {

    const filtredProduct = product.filter(item => item.id == clickId)



    return (
        <div className=''>
            {
                filtredProduct.map(item => (
                    <>
                        <img className='mb-[30px]' src={`${item.img}`} alt="" />
                        <span className='text-[#ADADAD] text-[20px]'>
                            {item.header}
                        </span>
                        <h2 className='text-[36px] font-semibold my-[20px]'>
                            {item.title}
                        </h2>
                        <p className='text-[24px] mb-[28px]'>{item.text}</p>
                        <Link to={`${item.url}`} target='_blank' className='text-[#000ED0] text-[24px] font-medium flex items-center'>Web site <img className='ml-[15px]' src={expo} alt="" /></Link>
                    </>
                ))
            }
        </div>
    )
}

export default TabItem