import React, { useEffect, useState } from 'react'
import file from "../../assets/icons/file.svg"
import News from '../../components/news/News'
import axios from 'axios'
import { baseUrl } from '../../constants/Constant'
function Documents() {
    const [docData, setDocData] = useState([])

    useEffect(() => {
        try {
            axios
                .get(
                    `${baseUrl}/docs`,
                )
                .then((res) => {
                    setDocData(res.data)
                })
                .catch((err) => {
                });

        } catch (error) { }
    }, [])

    return (
        <>
            <div className='md:container px-[3%] mx-auto my-[48px] md:my-[60px]'>
                <button className='bg-[#F5F5F5] py-[8px] px-[10px] text-[#5F6567] font-bold text-[13px] cursor-default rounded-lg'>Hujjatlar</button>
                <h1 className='text-[32px] md:text-[48px] font-bold my-[12px] md:my-[20px]'>
                    Bizning hujjatlarimiz
                </h1>
                <p className='text-[14px] md:text-[24px] text-[#5F6567] mb-[24px] md:mb-[60px]'>Kompaniya haqidagi ma’lumotlarning <br /> hujjatlashtirilgan talqini bilan tanishing</p>
                <div className='grid grid-cols-2 md:w-[60%]'>
                    {
                        docData.map(item => (
                            <div className='flex items-center '>
                                <img className='mr-[20px] w-[41px] md:w-[56px]' src={file} alt="" />
                                <div >
                                    <h3 className=' text-[20px] md:text-[32px]  font-semibold mb-[2px] cursor-pointer'>
                                        <a href={item.document} target="_blank">
                                            {item.name}
                                        </a>
                                    </h3>
                                    <span className='text-[14px] md:text-[16px] text-[#5F6567]'>{item.size}</span>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            {/* <News /> */}
        </>
    )
}

export default Documents
