import React, { useEffect, useState } from 'react'
import moneyImg from '../../assets/icons/money-recive.svg'
import crown from '../../assets/icons/crown.svg'
import profileDub from '../../assets/icons/profile-2user.svg'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { baseUrl } from '../../constants/Constant'

function Servise() {
    const navigate = useNavigate()
    const [service, setService] = useState([])

    useEffect(() => {
        try {
            axios
                .get(
                    `${baseUrl}/services`,
                )
                .then((res) => {
                    setService(res.data)

                })
                .catch((err) => {
                });

        } catch (error) { }
    }, [])

    return (
        <div className='bg-[#E5E7FA] py-[60px]'>
            <div className='md:container px-[3%] md:px-[0] mx-auto'>
                <button className='bg-[#FFFFFF] text-[#5F6567] rounded-lg text-[13px] font-bold py-[8px] px-[10px] mb-[20px] cursor-default'>BIZ NIMALAR TAKLIF QILAMIZ ?</button>
                <h1 className='text-[32px] md:text-[48px] font-bold mb-[60px]'>Bizning xizmatlarimiz</h1>
                <div className='grid grid-cols-2 md:grid-cols-3 gap-[16px] md:gap-[30px]'>
                    {
                        service.length > 0 && service[0].items.map(item => (
                            <div className='bg-[#FFFFFF] w-full py-[20px] px-[16px] md:p-[30px] flex flex-col items-between justify-between' key={item.id}>
                                <img className='w-[36px] md:w-[72px]' src={`${item.img}`} alt="" />
                                <h3 className='md:mt-[30px] md:mb-[20px] my-[16px] text-[20px]  md:text-[24px] font-semibold'>{item.title}</h3>
                                <p className='text-[14px] md:text-[16px]  text-[#666666] mb-[16px] md:mb-[30px]'>{item.text}</p>
                                <div className='md:border-t border-[#D9D9D9] md:pt-[20px]'>
                                    <span onClick={() => navigate("/services")} className='text-[#000ED0] cursor-pointer text-[13px] font-medium border-b-[3px] border-[#E5E7FA] pb-[2px]'>Batafsil ma’lumot</span>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className='w-full mt-[50px] flex items-center justify-center'>
                    <button onClick={() => navigate("/services")} className='bg-[#000ED0] text-[#fff] font-medium text-[18px] py-[15px] px-[20px] rounded-lg'>Barcha xizmatlar</button>
                </div>
            </div>
        </div>
    )
}

export default Servise