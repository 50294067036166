import React from 'react'
import global from "../../assets/icons/globalWhite.svg"
import arrowDown from "../../assets/icons/arrow-downWhite.svg"
import insta from '../../assets/icons/insta.svg'
import telegram from '../../assets/icons/telegram.svg'
import facebook from '../../assets/icons/facebook.svg'
import { useNavigate } from 'react-router-dom'

function Footer() {
    const navigate = useNavigate()
    const year = new Date().getFullYear()
    const location = window.location.pathname

    return (
        <div className={`bg-[#242627] pt-[60px] ${location == "/visaDoc" && 'hidden'}`}>
            <div className='md:container px-[3%] md:px-0 mx-auto'>
                <h1 className='text-[#fff] text-[75.27px] md:mb-[120px]'>IT Unisoft</h1>
                <div className='flex flex-col md:flex-row  md:justify-between gap-[60px] '>
                    <div>
                        <h3 className='text-[#fff] text-[28px] font-semibold'>
                            Foydali havolalar
                        </h3>
                        <ul className='text-[#fff] grid grid-cols-2 gap-[20px] mt-[39px]'>
                            <li className='cursor-pointer' onClick={() => navigate("/")}>Asosiy</li>
                            {/* <li>Yangiliklar</li> */}
                            <li className='cursor-pointer' onClick={() => navigate("/aboutUs")}>Biz haqimizda</li>
                            <li className='cursor-pointer' onClick={() => navigate("/contact")}>Aloqa</li>
                            <li className='cursor-pointer' onClick={() => navigate("/products")}>Mahsulotlar</li>
                            <li className='cursor-pointer' onClick={() => navigate("/documents")}>Hujjatlar</li>
                            <li className='cursor-pointer' onClick={() => navigate("/services")}>Xizmatlar</li>
                            {/* <li className='flex items-center'> <img src={global} alt="" /> <p className='px-[5px]'>O’zbek</p> <img className='cursor-pointer' src={arrowDown} alt="" /></li> */}
                        </ul>
                    </div>
                    <div>
                        <h3 className='text-[#fff] text-[28px] font-semibold '>
                            Kontaktlar
                        </h3>
                        <ul className='text-[#fff] mt-[39px] '>
                            <li>Qo’ng’iroq markazi: +998 99 123 45 67</li>
                            <li className='w-[229px] mt-[20px]'>Yuridik manzil: <br />
                                100029, Toshkent sh., Mirobod tumani, koʻch. Amir Temur, 53
                            </li>
                        </ul>
                    </div>
                    <div style={{ display: location == "/contact" && 'none' }} className='bg-[#D9D9D9] hidden md:flex w-[665px]  h-[220px]  rounded-lg'>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1498.6503389191348!2d69.26724413878486!3d41.30232210780483!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8adedfb03223%3A0x6dd78cd99c11e985!2z0YPQu9C40YbQsCDQkNGE0YDQvtGB0LjQsNCxIDQsINCi0LDRiNC60LXQvdGCLCBUb3Noa2VudCBTaGFocmksINCj0LfQsdC10LrQuNGB0YLQsNC9!5e0!3m2!1sru!2s!4v1707309878416!5m2!1sru!2s"
                            width="665" height="220"
                            style={{ border: 0, borderRadius: "10px" }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>
                    <div style={{ display: location == "/contact" && 'none' }} className='flex md:hidden'>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1498.6503389191348!2d69.26724413878486!3d41.30232210780483!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8adedfb03223%3A0x6dd78cd99c11e985!2z0YPQu9C40YbQsCDQkNGE0YDQvtGB0LjQsNCxIDQsINCi0LDRiNC60LXQvdGCLCBUb3Noa2VudCBTaGFocmksINCj0LfQsdC10LrQuNGB0YLQsNC9!5e0!3m2!1sru!2s!4v1707309878416!5m2!1sru!2s"
                            height="220" width="400"
                            style={{ border: 0, borderRadius: "10px" }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>

                </div>
                <div className='flex items-center gap-[23px] mt-[40px]'>
                    <a href="#">
                        <img src={insta} alt="" />
                    </a>
                    <a href="#">
                        <img src={telegram} alt="" />
                    </a>
                    <a href="#">
                        <img src={facebook} alt="" />
                    </a>
                </div>
            </div>
            <div className='border-[#D9D9D9] border-[0.5px] w-full mt-[60px] '>

            </div>
            <div className='md:container px-[3%] md:px-0 mx-auto py-[30px]'>
                <p className='text-[18px] font-normal text-[#fff]'>© {year} IT unisoft</p>
            </div>

        </div>
    )
}

export default Footer
