import React from 'react'
import Banner from '../../components/banner/Banner'
import Expirance from '../../components/expirance/Expirance'
import BlackFild from '../../components/blackFild/BlackFild'
import Inform from '../../components/inform/Inform'
import Servise from '../../components/servise/Servise'
import Projects from '../../components/projects/Projects'
import News from '../../components/news/News'
import GoodOne from '../../components/goodOne/GoodOne'

function Home() {
  return (
    <div className=''>
      <Banner />
      <Expirance />
      <BlackFild />
      <Inform />
      <Servise />
      <Projects />
      {/* <News /> */}
      <GoodOne />
    </div>
  )
}

export default Home