import React from 'react'
import toGeather from "../../assets/imgs/toGeather.png"

function ToGeather() {
    const location = window.location.pathname
    return (
        <>
            {
                location == "/aboutUs" ?
                    <div className='md:container px-[3%] mx-auto py-[50px] md:py-[120px] flex items-center justify-between gap-[69px]'>
                        <div>
                            <h1 className='text-[36px] md:text-[48px] font-bold'>
                                Birgalikda yaxshiroq
                            </h1>
                            <p className='text-[16px] md:text-[24px] font-normal text-[#666666] py-[25px]'>Biz ish muhimligini bilamiz, lekin o'yin ham shunday! Biz barcha sherikliklarimizni hurmat qilamiz va muhim muammolarni hal qiladigan va bir vaqtning o'zida yuzingizda tabassum qoldiradigan qiziqarli usullar bilan shug'ullanishga harakat qilamiz.
                                <br />
                                <br />
                                Biz ishimizni yaxshi ko'ramiz va mijozlarimizga yordam berishni yaxshi ko'ramiz. Hayot har bir daqiqadan zavqlanmaslik uchun juda qisqa!</p>
                            <button className='bg-[#000ED0] py-[15px] px-[20px] text-[#fff] rounded-lg text-[18px] font-normal mt-[20px]'>Bizga murojaat qiling</button>
                        </div>
                        <img className='hidden md:block' src={toGeather} alt="" />
                    </div>
                    :
                    <div className='md:container px-[3%] mx-auto py-[48px] md:py-[120px] flex flex-col md:flex-row md:items-center md:justify-between md:gap-[69px]'>
                        <div>
                            <h1 className='text-[32px] md:text-[48px] font-bold'>
                                Nima uchun aynan
                                IT Unisoft xizmatlarni tanlashingiz kerak?
                            </h1>
                            <p className='text-[14px] md:text-[24px] font-normal text-[#666666] mt-[20px] md:mt-[0] md:py-[25px]'>IT Unisoft xizmatlari korxonalarga turli xil imtiyozga ega bo’lgan kompaniyalar bilan raqobatda ustunlikni taqdim etadi. Outsors xizmatlarini tanlash biznes samaradorligini oshiradi va mijozlar o’rtasidagi ishonchni mustahkamlaydi. Bizning xizmatlarimiz sizning aniq maqsadlaringizga mos keladigan aniq ehtiyojlarni qondirish uchun moslashtirilishi mumkin.</p>
                            <button className='bg-[#000ED0] py-[15px] px-[20px] text-[#fff] rounded-lg text-[18px] font-normal my-[30px] md:my-[0] md:mt-[20px]'>Bizga murojaat qiling</button>
                        </div>
                        <img src={toGeather} alt="" />
                    </div>
            }
        </>
    )
}

export default ToGeather