import React from 'react'
import lines from "../../assets/icons/lines.svg"
import line2 from "../../assets/imgs/line.png"
function BlackFild() {
    return (
        <div className='px-[3%] w-full mb-[50px]'>
            <div className=' relative rounded-lg bg-[#242627]  flex md:justify-between'>
                <div className='flex flex-col justify-between items-between px-[10px] md:px-[30px] py-[20px]'>
                    <button className='bg-[#1F2021] text-[#E5E7FA] py-[8px] w-[140px] text-[13px] rounded-lg font-bold cursor-default mb-[180px] md:mb-[0]'>BIZ NIMA QILAMIZ ?</button>
                    <div className=''>
                        <h1 className='mb-[25%] md:mb-[0] text-[24px] md:text-[48px] font-bold md:w-[530px] text-[#E5E7FA]'>Murakkab zamon uchun IT ni soddalashtiramiz.</h1>
                        <img className='md:hidden absolute right-[0px] bottom-0' src={line2} alt="" />
                    </div>
                </div>
                <img className='hidden md:flex' src={lines} alt="" />
            </div>
        </div>
    )
}

export default BlackFild