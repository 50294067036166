import React, {useContext, useEffect, useMemo, useState} from 'react'
import {StateContext} from '../../context/Context'
import {useNavigate} from 'react-router-dom'
import {baseUrl} from '../../constants/Constant'
import axios from 'axios'

function Projects() {
    const {setClickedItemId} = useContext(StateContext)
    const navigate = useNavigate()
    const [productData, setProductData] = useState([])

    useMemo(() => {
        try {
            axios
                .get(
                    `${baseUrl}/products`,
                )
                .then((res) => {
                    setProductData(res.data)

                })
                .catch((err) => {
                });

        } catch (error) {
        }
    }, [])


    return (
        <div className='md:container px-[3%] md:px-[0] mx-auto mt-[80px]'>
            <button
                className='bg-[#F5F5F5] text-[#5F6567] py-[8px] font-bold text-[13px] px-[10px] rounded-lg cursor-default'>BIZ
                NIMALAR QILGANMIZ ?
            </button>
            <h1 className='text-[32px] md:text-[48px] font-bold mt-[20px] mb-[16px] md:mb-[60px]'>Biz qilgan loyihalar</h1>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-[30px]'>
                {
                    productData.length > 0 && productData.map((item, index) => (
                        <div className='md:border border-[#D9D9D9] md:p-[20px] rounded-[6px] flex flex-col gap-[20px] relative'
                             key={index}>
                            <img className='h-[200px] rounded-lg ' src={`${item.img}`} alt=""/>
                            <p className='text-[#ADADAD] text-[11px] font-semibold '>{item.header}</p>
                            <h2 className='text-[#0B0D31] text-[24px] font-semibold '>{item.title} </h2>
                            <p className='text-[#666666] text-[16px] font-normal mb-[50px] hidden md:block'>{item.text}</p>
                            <span
                                className='text-[#000ED0] hidden md:block  absolute bottom-[20px] cursor-pointer text-[13px] font-medium border-b-[3px] w-[100px] border-[#E5E7FA] pb-[2px]'
                                onClick={() => {
                                    navigate("/products");
                                    setClickedItemId(1)
                                }}>Batafsil ma’lumot</span>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Projects
