import React from 'react'
import xakker from '../../assets/imgs/xakker.png'
import chatGpt from '../../assets/imgs/chatGpt.png'
import arrowRight from '../../assets/icons/arrow-right.svg'

function News() {
  return (
    <div className='linier2  py-[60px] mt-[80px]'>
      <div className='container mx-auto '>
        <button className='bg-[#F5F5F5] text-[#5F6567] font-bold rounded-lg text-[13px] py-[8px] px-[10px] cursor-default' >BIZNING BLOG</button>
        <h1 className='text-[#FFFFFF] text-[48px] font-bold mt-[20px] mb-[60px]'>Yangiliklar</h1>
        <div className='grid grid-cols-3 gap-[40px]'>
          <div className='border border-[#D9D9D9] bg-[#fff] p-[20px] rounded-[6px] flex flex-col gap-[20px] relative'>
            <img src={xakker} alt="" />
            <p className='text-[#ADADAD] text-[11px] font-semibold '>Moliya / Kiberxavsizlik</p>
            <h2 className='text-[#0B0D31] text-[24px] font-semibold '>OGOHLIKKA CHAQIRAMIZ!</h2>
            <p className='text-[#666666] text-[16px] font-normal mb-[50px]'>Biz moliya yoki ishlab chiqarish, ta’lim, internet-bozorlar, sog'liqni saqlash kabi muayyan sohalarga xizmat ko'rsatib kelmoqdamiz va sizning unikal ehtiyojlaringizni qondiradigan maxsus echimlarni taklif qilamiz.</p>
            <span className='text-[#242627]  absolute bottom-[20px] cursor-pointer text-[13px] font-medium'>Batafsil ma’lumot</span>
          </div>
          <div className='border border-[#D9D9D9] bg-[#fff] p-[20px] rounded-[6px] flex flex-col gap-[20px] relative'>
            <img src={chatGpt} alt="" />
            <p className='text-[#ADADAD] text-[11px] font-semibold '>Ta’lim / Moliya</p>
            <h2 className='text-[#0B0D31] text-[24px] font-semibold '>ChatGPT bilan moliyani boshqarish</h2>
            <p className='text-[#666666] text-[16px] font-normal mb-[50px]'>Biz sizga xarajatlarni kamaytirishga va maxsulotingizni yaxshilashga yordam beradigan arzon it-echimlarni taklif etamiz.</p>
            <span className='text-[#242627]  absolute bottom-[20px] cursor-pointer text-[13px] font-medium'>Batafsil ma’lumot</span>
          </div>
          <div className='border cursor-pointer border-[#D9D9D9] bg-[#fff] rounded-lg flex items-center justify-center self-end  h-[54px] '>
            <p className='flex items-center'>Barcha yangiliklarni ko’rish <img className='ml-[10px]' src={arrowRight} alt="" /></p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default News