import React, { useContext, useEffect, useState } from 'react'
import { StateContext } from '../../context/Context'
import axios from 'axios'
import { baseUrl } from '../../constants/Constant'
import TabItem from './TabItem'

function TabPro() {
    const { clickedItemId, setClickedItemId, } = useContext(StateContext)

    const [productData, setProductData] = useState([])

    useEffect(() => {
        try {
            axios
                .get(
                    `${baseUrl}/products`,
                )
                .then((res) => {
                    setProductData(res.data)

                })
                .catch((err) => {
                });

        } catch (error) { }
    }, [])

    const handleItemClick = (itemId) => {
        setClickedItemId(itemId);
    };
    return (
        <div className='container mx-auto flex gap-[30px] justify-around'>
            <div>
                <button className='bg-[#F5F5F5] rounded-lg py-[8px] px-[10px] text-[#5F6567] text-[13px] font-bold cursor-default'>BIZ NIMALAR QILGANMIZ ?</button>
                <h2 className='text-[48px] font-bold mt-[20px] mb-[40px] w-[406px]'>Biz qilgan loyihalar</h2>
                {
                    productData.map(item => (
                        <div onClick={() => handleItemClick(item.id)}
                            className={`${clickedItemId === item.id
                                ? "bg-[#E5E7FA] py-[20px] px-[40px] cursor-pointer rounded-lg text-[#000ED0] font-bold"
                                : "py-[20px] px-[40px] cursor-pointer"
                                }`} >
                            <p>{item.title}</p>
                        </div>

                    ))
                }
            </div>
            <TabItem  product={productData} clickId={clickedItemId} />
        </div>
    )
}

export default TabPro