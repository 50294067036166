import React, { useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import { baseUrl } from '../../constants/Constant';

function Sevis() {
    const [service, setService] = useState([])

    useMemo(() => {
        try {
            axios
                .get(
                    `${baseUrl}/services`,
                )
                .then((res) => {
                    setService(res.data)

                })
                .catch((err) => {
                    console.log(err);
                });

        } catch (error) {
        }
    }, [])

    return (
        <div className='grid grid-cols-1 gap-[16px] md:gap-[30px] py-[30px] md:py-[80px] px-[16px] md:px-[50px] bg-[#E5E7FA]'>
            {
                service.length > 0 && service[0].items.map(item => (
                    <div className='p-[30px] flex justify-between bg-[#fff] w-full rounded-lg md:rounded-none md:w-[90%]' key={item.id}>
                        <div className='flex flex-col md:flex-row md:items-center'>
                            <img className='w-[36px] md:w-[144px] mr-[30px]' src={`${item.img}`} alt="" />
                            <dir>
                                <h3 className='text-[20px] md:text-[36px] font-semibold mt-[16px] md:mt-[0]'>{item.title}</h3>
                                <p className='text-[14px] md:text-[24px]  text-[#666666] md:w-[679px] mt-[12px] md:mt-[0]'>{item.text}</p>
                            </dir>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default Sevis
