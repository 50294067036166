import React from 'react'
import Sevis from '../../components/servise/Sevis'
import ToGeather from '../../components/toGeather/ToGeather'

function Services() {
    return (
        <div>
            <div className='flex flex-col justify-center items-center my-[60px]'>
                <button className='bg-[#F5F5F5] py-[8px] px-[10px] text-[#5F6567] font-bold text-[13px] rounded-lg'>YECHIMLAR</button>
                <h1 className='text-[32px] md:text-[48px] font-bold text-[#0B0D31] my-[20px] md:w-[837px] text-center'>Biz taklif qilayotgan xizmatlar va yechimlar</h1>
                <p className='text-[16px] md:text-[24px] md:w-[880px] text-center'>Ishonchli va samarali texnologik echimlarimizga sarmoya kiritib, kompaniyangizni yangi cho'qqilarga olib chiqing.</p>
            </div>
            <Sevis />
            <ToGeather />
        </div>

    )
}

export default Services