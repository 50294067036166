import React from 'react'
import blackFild from "../../assets/imgs/blackFild.png"
import lines from "../../assets/icons/lines.svg"
import line2 from "../../assets/imgs/line.png"

function StoryBlackFild() {
    return (
        <div>
            <div style={{ backgroundImage: `url(${blackFild})` }} className='hidden md:block w-[90%] mx-auto mb-[120px] bg-no-repeat bg-cover bg-right p-[30px] rounded-lg'>
                <button className='bg-[#1F2021] text-[#E5E7FA] py-[8px] w-[140px] text-[13px] rounded-lg font-bold cursor-default'>BIZ NIMA QILAMIZ ?</button>
                <h1 className='text-[48px] font-bold w-[887px] text-[#E5E7FA] mt-[77px] leading-[57px]'>Bizning madaniyatimiz bizni raqobatdoshlarimizdan ajratib turadigan beshta asosiy tamoyil ustiga qurilgan.</h1>
            </div>
            <div className='px-[3%] flex md:hidden w-full mb-[50px]'>
                <div className=' relative rounded-lg bg-[#242627]  flex md:justify-between'>
                    <div className='flex flex-col justify-between items-between px-[30px] py-[20px]'>
                        <button className='bg-[#1F2021] text-[#E5E7FA] py-[8px] w-[140px] text-[13px] rounded-lg font-bold cursor-default mb-[180px] md:mb-[0]'>BIZ NIMA QILAMIZ ?</button>
                        <div className=''>
                            <h1 className='mb-[25%] md:mb-[0] text-[24px] md:text-[48px] font-bold md:w-[530px] text-[#E5E7FA]'>Bizning madaniyatimiz bizni raqobatdoshlarimizdan ajratib turadigan beshta asosiy tamoyil ustiga qurilgan.</h1>
                            <img className='md:hidden absolute right-[0px] bottom-0' src={line2} alt="" />
                        </div>
                    </div>
                    <img className='hidden md:flex' src={lines} alt="" />
                </div>
            </div>
        </div>
    )
}

export default StoryBlackFild