import { Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/navbar/Navbar';
import Home from './pages/home/Home';
import Footer from './components/footer/Footer';
import AboutUs from './pages/aboutUs/AboutUs';
import Services from './pages/services/Services';
import OurProduct from './pages/ourProduct/OurProduct';
import Contact from './pages/contact/Contact';
import Documents from './pages/documents/Documents';
import { StateContext } from './context/Context';
import React, { useState } from 'react';
import RedocComponent from './pages/forDev/RedocComponent';


function App() {
  const [clickedItemId, setClickedItemId] = useState(1);


  return (
    <div className="App">
      <StateContext.Provider value={{ clickedItemId, setClickedItemId }}>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/aboutUs' element={<AboutUs />} />
          <Route path='/services' element={<Services />} />
          <Route path='/products' element={<OurProduct />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/documents' element={<Documents />} />
          {/* <Route path='/forDev' element={<ForDev />} /> */}
          <Route path='/visaDoc' element={<RedocComponent />} />
        </Routes>
        <Footer />
      </StateContext.Provider>
    </div>
  );
}

export default App;
