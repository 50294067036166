import React, { useEffect, useState } from 'react'
import StoryTitle from '../../components/story/StoryTitle'
import StoryDec from '../../components/story/StoryDec'
import StoryBlackFild from '../../components/blackFild/StoryBlackFild'
import Inform from '../../components/inform/Inform'
import ExpiranceGroup from '../../components/expirance/ExpiranceGroup'
import ToGeather from '../../components/toGeather/ToGeather'
import StoryDecSm from '../../components/story/StoryDecSm'

function AboutUs() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    useEffect(() => {
        window.addEventListener('resize', (e) => {
            setWindowWidth(window.innerWidth)
        })
    }, [])
    return (
        <div>
            <StoryTitle />
            {windowWidth > 768 ? <StoryDec /> : <StoryDecSm />}
            <StoryBlackFild />
            <Inform />
            <ExpiranceGroup />
            <ToGeather />
        </div>
    )
}

export default AboutUs