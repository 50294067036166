import React from 'react'

function ExpiranceGroup() {
    return (
        <div className='linier4 flex flex-col items-center justify-center gap-[30px] py-[80px] px-[3%] md:px-[0] md:py-[190px]'>
            <h1 className='text-[32px] md:text-center   md:w-full text-left md:text-[64px] font-bold'>Tajribaga asoslangan jamoa</h1>
            <p className='md:w-[80%] text-center text-[16px] md:text-[24px] color-[#666666d]'>Biz o'z tarmog'imizni milliy miqyosda qurishda davom etmoqdamiz va eng yaxshi xizmat va texnik ko'nikmalarga ega bo'lgan jonli jamoani birlashtirmoqdamiz. O’zbekiston bo'ylab 30 dan ortiq xodimlar bilan bizning jamoamiz tez suratlarda o'sib bormoqda.</p>
            <button className='bg-[#000ED0] py-[12px] px-[16px] md:py-[15px] md:px-[20px] text-[#fff] rounded-lg text-[18px] font-normal mt-[20px]'>Biz bilan bog’laning</button>
        </div>
    )
}

export default ExpiranceGroup