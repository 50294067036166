import React, { useEffect, useMemo, useState } from 'react'
import logo from "../../assets/logos/logo.svg"
import menu from "../../assets/icons/menu.svg"
import arrowRight from "../../assets/icons/arrow-right.svg"
import closeCir from "../../assets/icons/close-circle.svg"
import { useNavigate } from 'react-router-dom'
import { baseUrl } from '../../constants/Constant'
import axios from 'axios'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/material'



function Navbar() {
    const navigate = useNavigate()
    const location = window.location.pathname
    const [contact, setContact] = useState([])
    const [phone, setPhone] = useState("")
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const [isOpen, setIsOpen] = useState(false);


    const toggleDropdown = () => setIsOpen(!isOpen);


    useMemo(() => {
        try {
            axios
                .get(
                    `${baseUrl}/contacts`,
                )
                .then((res) => {
                    setContact(res.data)
                })
                .catch((err) => {
                });

        } catch (error) {
        }
    }, [])

    useEffect(() => {
        contact.map(item => setPhone(item.phone))
    }, [contact])


    return (
        <div className={`md:container px-[3%] md:px-[0] mx-auto ${location == "/visaDoc" && 'hidden'}`}>
            <div className='flex items-center border-b border-[#D9D9D9] justify-between py-[30.5px]'>
                <img onClick={() => navigate('/')} className='cursor-pointer max-w-[106px]' src={logo} alt="" />
                <ul className='hidden md:flex items-center gap-[20px] font-normal'>
                    <li style={{ color: `${location === '/' ? '#000ED0' : 'black'}` }} onClick={() => {
                        navigate('/')
                    }} className='cursor-pointer'>Asosiy
                    </li>
                    <li style={{ color: `${location === '/aboutUs' ? '#000ED0' : 'black'}` }} onClick={() => {
                        navigate('/aboutUs')
                    }} className='cursor-pointer'>Biz haqimizda
                    </li>
                    <li style={{ color: `${location === '/services' ? '#000ED0' : 'black'}` }}
                        onClick={() => navigate('/services')} className='cursor-pointer'>Xizmatlar
                    </li>
                    <li style={{ color: `${location === '/products' ? '#000ED0' : 'black'}` }}
                        onClick={() => navigate("/products")} className='cursor-pointer'>Mahsulotlar
                    </li>
                    {/* <li style={{ color: `${location === '/news' ? '#000ED0' : 'black'}` }} className='cursor-pointer'>Yangiliklar</li> */}
                    <li style={{ color: `${location === '/documents' ? '#000ED0' : 'black'}` }}
                        onClick={() => navigate('/documents')} className='cursor-pointer'>Hujjatlar
                    </li>

                    <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                        <li onClick={handleClick} className='cursor-pointer'>
                            Dasturchilarga
                        </li>
                    </Box>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&::before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={() => (navigate("/visaDoc"), handleClose())}>Visa</MenuItem>
                    </Menu>
                    <li style={{ color: `${location === '/contact' ? '#000ED0' : 'black'}` }}
                        onClick={() => navigate("/contact")} className='cursor-pointer'>Aloqa
                    </li>
                    {/* <li className='flex items-center'> <img src={global} alt="" /> <p className='px-[5px]'>O’zbek</p> <img className='cursor-pointer' src={arrowDown} alt="" /></li> */}
                </ul>
                <div className='hidden md:flex flex-col items-center justify-center'>
                    <a href="tel:">
                        <button className='bg-[#F5F5F5] flex items-center px-[10px] py-[4px] rounded-lg font-medium'>Mijozlarni
                            qo'llab-quvvatlash <img className='ml-[5px]' src={arrowRight} alt="" />
                        </button>
                    </a>
                    {
                        contact.map((item, index) => (

                            <h1 className='font-medium mt-[5px]' key={index}>

                                {item.phone}
                            </h1>
                        ))
                    }
                </div>
                <button onClick={() => navigate("/contact")}
                    className='bg-[#000ED0] hidden md:flex text-[#fff] font-normal px-[20px] py-[15px] rounded-lg'>Biz bilan aloqa
                </button>
                <img onClick={toggleDropdown} className=' flex md:hidden' src={menu} alt="" />
            </div>
            <div
                className={`fixed inset-0  w-full h-full bg-black bg-opacity-50 ${isOpen ? 'block' : 'hidden'}`}
                onClick={toggleDropdown}
                style={{ zIndex: 40 }}
            ></div>
            <div
                className={`fixed top-0 left-0 w-full py-[5%] bg-white z-50 transition-transform duration-500 ease-in-out ${isOpen ? 'translate-y-0' : '-translate-y-full'}`}
            >
                <div className='z-[50] flex flex-col gap-[20px] pt-[10px] pb-[24px]'>
                    <div className='flex px-[3%] items-center justify-between'>
                        <img onClick={() => navigate('/')} className='cursor-pointer w-[106px]' src={logo} alt="" />
                        <img onClick={toggleDropdown} src={closeCir} alt="" />
                    </div>
                    <div className='flex-col items-center justify-center px-[3%]'>
                        <a href={"tel:" + phone}>
                            <button className='bg-[#F5F5F5] flex items-center px-[10px] py-[4px] rounded-lg font-medium'>Mijozlarni
                                qo'llab-quvvatlash <img className='ml-[5px]' src={arrowRight} alt="" />
                            </button>
                        </a>
                        {
                            contact.map((item, index) => (
                                <h1 className='font-medium mt-[5px]' key={index}>
                                    {item.phone}
                                </h1>
                            ))
                        }
                    </div>
                    <ul className='flex flex-col  font-normal'>
                        <li style={{ color: `${location === '/' ? '#000ED0' : 'black'}` }} onClick={() => {
                            navigate('/')
                        }} className='cursor-pointer px-[3%]'>Asosiy
                        </li>
                        <div className='h-[1px]  bg-[#D9D9D9] my-[16px]'>

                        </div>
                        <li style={{ color: `${location === '/aboutUs' ? '#000ED0' : 'black'}` }} onClick={() => {
                            navigate('/aboutUs')
                        }} className='cursor-pointer px-[3%]'>Biz haqimizda
                        </li>
                        <div className='h-[1px]  bg-[#D9D9D9] my-[16px]'>

                        </div>
                        <li style={{ color: `${location === '/services' ? '#000ED0' : 'black'}` }}
                            onClick={() => navigate('/services')} className='cursor-pointer px-[3%]'>Xizmatlar
                        </li>
                        <div className='h-[1px]  bg-[#D9D9D9] my-[16px]'>

                        </div>
                        <li style={{ color: `${location === '/products' ? '#000ED0' : 'black'}` }}
                            onClick={() => navigate("/products")} className='cursor-pointer px-[3%]'>Mahsulotlar
                        </li>
                        <div className='h-[1px]  bg-[#D9D9D9] my-[16px]'>

                        </div>
                        {/* <li style={{ color: `${location === '/news' ? '#000ED0' : 'black'}` }} className='cursor-pointer'>Yangiliklar</li> */}
                        <li style={{ color: `${location === '/documents' ? '#000ED0' : 'black'}` }}
                            onClick={() => navigate('/documents')} className='cursor-pointer px-[3%]'>Hujjatlar
                        </li>
                        <div className='h-[1px]  bg-[#D9D9D9] my-[16px]'>

                        </div>
                        <li style={{ color: `${location === '/contact' ? '#000ED0' : 'black'}` }}
                            onClick={() => navigate("/contact")} className='cursor-pointer px-[3%]'>Aloqa
                        </li>
                        <button className='bg-[#000ED0] text-[#fff] text-[18px] mt-[20%] py-[15px] rounded-lg w-[90%] mx-auto'>
                            Biz bilan aloqa
                        </button>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Navbar
