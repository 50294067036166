import React from 'react';
import { RedocStandalone } from 'redoc';

const RedocComponent = () => {
  return (
    <div style={{ margin: 0, padding: 0 }}>
      <RedocStandalone specUrl="/partner_api.yaml" />
    </div>
  );
};

export default RedocComponent;
