import React, { useEffect, useState } from 'react'
import TabPro from '../../components/TabPro/TabPro'
import ToGeather from '../../components/toGeather/ToGeather'
import TabProSm from '../../components/TabPro/TabProSm'

function OurProduct() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    useEffect(() => {
        window.addEventListener('resize', (e) => {
            setWindowWidth(window.innerWidth)
        })
    }, [])
    return (
        <div className='mt-[60px]'>
            {windowWidth > 768 ? <TabPro /> : <TabProSm />}
            <ToGeather />
        </div>
    )
}

export default OurProduct