import React from 'react'
import moneyImg from '../../assets/icons/money-recive.svg'
import crown from '../../assets/icons/crown.svg'
import profileDub from '../../assets/icons/profile-2user.svg'
import maximize from '../../assets/icons/maximize.svg'
function Inform() {
    const location = window.location.pathname
    const infoData = [
        {
            img: moneyImg,
            title: "Qulay Narxlar",
            dec: "Biz sizga xarajatlarni kamaytirishga va maxsulotingizni yaxshilashga yordam beradigan arzon it-echimlarni taklif etamiz."
        },
        {
            img: crown,
            title: "Innovatsion Texnologiyalar",
            dec: "Biz eng yangi texnologiya tendentsiyalari bilan dolzarb bo'lib qolamiz va raqobatda oldinda qolishingizga yordam beradigan innovatsion yechimlarni taklif qilamiz."
        },
        {
            img: profileDub,
            title: "Industriyadagi Ekspertiza",
            dec: "Biz moliya yoki ishlab chiqarish, ta’lim, internet-bozorlar, sog'liqni saqlash kabi muayyan sohalarga xizmat ko'rsatib kelmoqdamiz va sizning unikal ehtiyojlaringizni qondiradigan maxsus yechimlarni taklif qilamiz."
        },
        {
            img: maximize,
            title: "Moslashuvchan",
            dec: "Bizning yechimlarimiz sizning biznesingiz bilan kengayish hususiyatiga ega, bu sizning tikkan  investitsiyalaringizdan ko’proq qiymatga ega bo'lishingizni ta'minlaydi."
        },
    ]
    const storyInfoData = [
        {
            img: moneyImg,
            title: "Qiziqish",
            dec: "Biz ajoyib yechimlarni taqdim etishga katta ishtiyoq bilan qaraymiz."
        },
        {
            img: crown,
            title: "Hurmat",
            dec: "Biz bir-birimizga hushmuomala va hurmatdamiz, hatto qiyin bo'lsa ham."
        },
        {
            img: profileDub,
            title: "G’alabagacha",
            dec: "Biz o'z ishimizni puxta bajaramiz va umumiy maqsad oxirigacha harakat qilamiz"
        },
        {
            img: maximize,
            title: "Birlashtirilgan",
            dec: "Biz jamoa bo'lib ishlaymiz va uzluksiz tajriba yaratish uchun bir-birimizga ishonamiz."
        },
    ]


    return (
        <>
            {
                location !== "/aboutUs" &&
                <div className='md:container px-[3%] md:px-[0] mx-auto grid grid-cols-1  md:grid-cols-2 lg:grid-cols-4 gap-[3%] pb-[60px] md:border-b border-[#D9D9D9] mb-[60px]'>
                    {
                        infoData.map((item, index) => (
                            <div className='' key={index}>
                                <img src={item.img} alt="" />
                                <h3 className='font-semibold text-[24px] mt-[30px] mb-[20px] text-[#0B0D31] '>{item.title}</h3>
                                <p className='font-normal text-[16px] text-[#666666] w-[280px]'>{item.dec}</p>
                            </div>
                        ))
                    }
                </div>
            }
            <div className='md:container px-[3%] md:px-[0] mx-auto grid grid-cols-2 md:grid-cols-4 gap-[3%] pb-[60px]  mb-[60px]'>
                {
                    location === "/aboutUs" && storyInfoData.map((item,index) => (
                        <div className='' key={index}>
                            <img src={item.img} alt="" />
                            <h3 className='font-semibold text-[24px]  mt-[30px] mb-[20px] text-[#0B0D31] '>{item.title}</h3>
                            <p className='font-normal text-[16px] text-[#666666] md:w-[280px]'>{item.dec}</p>
                        </div>
                    ))
                }
            </div>
        </>
    )
}

export default Inform
