import React from 'react'

function StoryTitle() {
    return (
        <div className='flex flex-col justify-center items-center pt-[60px] mb-[98px]'>
            <button className='bg-[#F5F5F5] py-[8px] px-[10px] text-[13px] rounded-lg text-[#5F6567] font-bold cursor-default'>MA’LUMOT</button>
            <h1 className='text-[36px] md:text-[48px] font-bold text-center my-[20px] text-[#0B0D31]'>IT Unisoft <br />
                Mukammallikni yetkazib berish uchun
            </h1>
            <p className='text-[16px] md:text-[24px] font-medium text-[#5F6567]'>Hammaning hikoyasi bor. Mana bizniki.</p>
        </div>
    )
}

export default StoryTitle