import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { baseUrl } from '../../constants/Constant';
import axios from 'axios';
import arrow from "../../assets/icons/arrow-down.svg";
import expo from "../../assets/icons/export.svg";
import { Link } from 'react-router-dom';

function TabProSm() {
    const [expanded, setExpanded] = useState(false);
    const [productData, setProductData] = useState([]);

    useEffect(() => {
        axios.get(`${baseUrl}/products`)
            .then((res) => {
                setProductData(res.data);
            })
            .catch((err) => {
                // handle error
            });
    }, []);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className='px-[3%]'>
            {productData.map(item => (
                <Accordion
                    key={item.id}
                    expanded={expanded === `panel${item.id}`}
                    onChange={handleChange(`panel${item.id}`)}
                    sx={{ backgroundColor: expanded === `panel${item.id}` ? '#E5E7FA' : 'transparent',boxShadow:"none",border:"1px solid #D9D9D9" }} // Change background color here
                >
                    <AccordionSummary
                        expandIcon={<img className='w-[18px]' src={arrow} alt="Expand" />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: 700, fontSize: "14px", color: expanded === `panel${item.id}` ? "#000ED0" : "black" }}>
                            {item.title}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <img className='mb-[10px]' src={item.img} alt={item.title} />
                        <span className='text-[#ADADAD] text-[13px]'>
                            {item.header}
                        </span>
                        <h2 className='text-[24px] font-semibold mt-[16px] mb-[12px]'>
                            {item.title}
                        </h2>
                        <p className='text-[16px] mb-[28px]'>{item.text}</p>
                        <Link to={item.url} target='_blank' className='text-[#000ED0] text-[14px] font-medium flex items-center'>
                            Web site <img className='ml-[8px] w-[14px]' src={expo} alt="Export" />
                        </Link>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
}

export default TabProSm;
